<template>
 <router-view></router-view>
</template>
<script>

export default {
  name: 'App',
  components: {

  },
 methods : {
  checkNotificationPromise() {
   try {
     Notification.requestPermission().then();
   } catch (e) {
    return false;
   }
   return true;
  },

  askNotificationPermission() {
   // function to actually ask the permissions
   // Let's check if the browser supports notifications
   if (!('Notification' in window)) {
     console.log("This browser does not support notifications.");
   } else if (this.checkNotificationPromise()) {
    Notification.requestPermission().then(() => {
    });
   } else {
    Notification.requestPermission(() => {
     //console.log(permission)
    });
   }
  },
 },
  mounted() {
   this.askNotificationPermission();
  },

}
</script>
<style>
:root{
  --vs-font-size: 0.8125rem !important;
  --vs-line-height: 1.5;
}
.vs__search, .vs__search :focus{
  padding: 4px !important;
  color: #989797 !important;
}

</style>
